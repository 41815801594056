






















































































































































































































































































































































































































































































































































.admin-a2p-detail {
  .radio-wrapper {
    >label {
      display: inline-block;
      width: 625px;
    }

    .radio-group {
      display: inline-block;
    }
  }
}
